import store from '@/store'
import {reactive, ref, watch} from '@vue/composition-api'
import {useToast} from "vue-toastification/composition";
import {useRouter} from '@core/utils'

export default function useSections() {
  const deleteConfirmState = reactive({
    id: null,
    visible: false,
  })

  const itemsListTable = ref([])
  const {router} = useRouter()

  const itemData = ref({});
  const seoData = ref({});
  const bodyHtml = ref(null);

  // Table Handlers
  const tableColumns = [
    {text: '#ID', align: 'start', value: 'id', sortable: false},
    {text: 'NAME', value: 'name', sortable: false},
    {text: 'SLUG', value: 'slug', sortable: false},
    {text: '', value: 'actions', align: 'center', sortable: false},
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const totalItemListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])
  const errorMessages = ref([])

  const toast = useToast()

  const deleteItem = id => {
    store
      .dispatch('app-content-sections/delete', {
        id: id,
      })
      .then(response => {
        fetchItems()
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
        for (const key in error.response.data.errors) {
          toast.warning(error.response.data.errors[key][0])
        }
      })
  }

  const toggleItemPublishState = (id, state) => {
    store
      .dispatch('app-content-sections/update', {
        id: id,
        data: {
          is_published: state,
        },
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
        for (const key in error.response.data.errors) {
          toast.warning(error.response.data.errors[key][0])
        }
      })
  }

  const updateItem = (id, data, showError = false) => {
    store
      .dispatch('app-content-sections/update', {
        id: id,
        data: data,
      })
      .then(() => {
        toast.success('Updated')
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
        if (showError) {

          for (const key in error.response.data.errors) {
            toast.warning(error.response.data.errors[key][0])
          }
        } else {
          toast.warning('Validation errors')
        }
      })
  }

  const storeItem = (data) => {
    store
      .dispatch('app-content-sections/store', data)
      .then(response => {
        toast.success('Created!')
        router.push({name: 'content-sections-index'})
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
        for (const key in error.response.data.errors) {
          toast.warning(error.response.data.errors[key][0])
        }
      })
  }

  const updateOneProperty = (id, name, value) => {
    updateItem(id, {
      [name]: value,
    }, true)
  }

  const fetchItem = (id) => {
    store
      .dispatch('app-content-sections/show', {
        id: id,
      })
      .then(response => {
        itemData.value = response.data
        seoData.value = response.data.seo
        bodyHtml.value = response.data.body
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
      })
  }

  const makeItem = () => {
    const template = {
      slug: null,
      name: null,
    }

    itemData.value = template
    seoData.value = template.seo
  }

  const fetchItems = () => {
    store
      .dispatch('app-content-sections/list', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
      })
      .then(response => {
        const filteredData = response.data
        itemsListTable.value = filteredData
        totalItemListTable.value = filteredData.length
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        errorMessages.value = error.response.data.errors
      })
  }

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    fetchItems()
  })

  return {
    bodyHtml,
    tableColumns,
    searchQuery,
    options,
    itemData,
    seoData,
    itemsListTable,
    statusFilter,
    totalItemListTable,
    loading,
    selectedTableData,
    deleteConfirmState,
    errorMessages,
    toast,
    makeItem,
    storeItem,
    fetchItem,
    fetchItems,
    deleteItem,
    updateItem,
    updateOneProperty,
    toggleItemPublishState,
  }
}
